"use client";

import React from "react";
import { Inter } from "next/font/google";
import "bootstrap/dist/css/bootstrap.css";
import "react-device-frameset/styles/marvel-devices.css";
import "css-device-frames/dist/device-frames.css";

import "@/app/[locale]/(landingPage)/(homepageLayout)/home/global.css";
import { Providers } from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/Providers";
import Script from "next/script";
const inter = Inter({ subsets: ["latin"] });
export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <main className={inter.className}>
      {process.env.NODE_ENV !== "development" && (
        <Script
          id="zalo-oa"
          strategy="afterInteractive"
          src="https://sp.zalo.me/plugins/sdk.js"
        />
      )}

      <Providers>
        {children}

        <div
          dangerouslySetInnerHTML={{
            __html: `
        <div class="zalo-chat-widget" data-oaid="167003496959089065" data-welcome-message="Rất vui khi được hỗ trợ bạn!" data-autopopup="0" data-width="" data-height=""></div>
        `,
          }}
        />
      </Providers>
    </main>
  );
}
